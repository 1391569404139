export const mapMarkerImage = "map_marker.svg";
export const mapMarkerImageWhite = "map_marker_white.svg";
export const mapMarkerImageSo = "map_marker_so.svg";
export const mapMarkerImageProgram = "map_marker_prog.svg";
export const mapMarkerImageSoProgram = "map_marker_so_prog.svg";

export function createMarkerIconWithImage(image: string) {
  return L.icon({
    iconUrl: "/images/" + image,
    iconRetinaUrl: "/images/" + image,
    iconSize: [28, 40],
  });
}

export function triggerResizeEvent() {
  const resizeEvent = window.document.createEvent("UIEvents");
  resizeEvent.initUIEvent("resize", true, false, window, 0);
  window.dispatchEvent(resizeEvent);
}
